import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { HeaderContext } from "../Includes/HeaderContext";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";

export default function Contact() {
	const { register, handleSubmit, errors } = useForm();
	const [ContactResponse, setContactResponse] = useState([]);
	const { HeaderData } = useContext(HeaderContext);

	console.log(HeaderData);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [ContactResponse]);

	const onSubmit = (data, e) => {
		console.log(data);

		Axios.post(
			"https://rampurhospital.lumbini.gov.np/api/public/api/contact/store",
			data
		)
			.then(function (response) {
				console.log(response);
				setContactResponse(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
		e.target.reset();
	};

	return (
		<React.Fragment>
			<div className="contact-container">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="contact-wrapper">
								<div className="contact-detail">
									<div className="img-container">
										<img
											src={require("../images/logo.png")}
											alt=""
											className="img-fluid"
										/>
									</div>
									{HeaderData[0] && (
										<>
											<div className="company_name">
												{HeaderData[0].company}
											</div>
											<div className="company_address">
												{HeaderData[0].address}
											</div>
											<div className="company_phone_number">
												{HeaderData[0].phone}
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="contact-form">
								<div className="main-title">
									{" "}
									<FormattedMessage
										id="contact"
										defaultMessage={defineMessages.navbar.contact}
									/>
								</div>
								{ContactResponse.success && (
									<div className="alert alert-success mt-5">
										{ContactResponse.success}
									</div>
								)}
								<form onSubmit={handleSubmit(onSubmit)} id="contact-form">
									<div className="form-group">
										<label htmlFor="">Name</label>
										<input
											type="text"
											className="form-control"
											name="name"
											ref={register({ required: true })}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">Mobile Number</label>
										<input
											type="number"
											name="phone"
											className="form-control"
											ref={register({
												required: true,
											})}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">Email</label>
										<input
											type="email"
											name="email"
											className="form-control"
											ref={register({ required: true })}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">Message</label>

										<textarea
											name="body"
											id=""
											cols="10"
											rows="5"
											className="form-control"
											ref={register({ required: true })}
										></textarea>
									</div>
									<div className="button-container text-right">
										<input type="submit" className="btn btn-primary" />
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226182.58659786065!2d82.82421453069716!3d27.652287424411735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3996fc3bf6968289%3A0x5feafb1771523aeb!2sKapilvastu!5e0!3m2!1sen!2snp!4v1584703409476!5m2!1sen!2snp"
				width="100%"
				height="450"
				frameBorder="0"
				allowFullScreen=""
				aria-hidden="false"
				tabIndex="0"
			></iframe>
		</React.Fragment>
	);
}
