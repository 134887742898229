import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import {Link} from 'react-router-dom';
import { HeaderContext } from "../Includes/HeaderContext";

export default function Gallery() {
	const [AlbumRes, setAlbumRes] = useState();
	const [err, setErr] = useState();
	const { LangData } = useContext(HeaderContext);

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/album/" + LangData[0];

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await Axios.get(getUrl);
				setAlbumRes(response.data.data);
				console.log(response.data.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
		
	}, []);

	return (
		<div className="gallery-container">
			<div className="container">
				<h2>Gallery</h2>
				<div className="row">
					
						{AlbumRes &&
							AlbumRes.map((album, index) => (
								<div className="col-md-3">
								<Link to={`/gallery/${album.slug}/${LangData[0]}`} className="gallery-item">
									<div className="img-container">
										<img src={album.cover} alt={album.name} className="img-fluid"/>
									</div>
									<div className="name">
										{album.name}
									</div>
								</Link>
								</div>
							))}
				
				</div>
			</div>
		</div>
	);
}
