import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";
import { useForm } from "react-hook-form";
import Axios from "axios";

export default function Appointment() {
	const { register, handleSubmit } = useForm();
	const [AppointmentResponse, setAppointmentResponse] = useState([]);
	const [Department, setDepartment] = useState([]);

	useEffect(() => {
		Axios.get("https://rampurhospital.lumbini.gov.np/api/public/api/appointment")
			.then((response) => {
				setDepartment(response.data.data.department);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const onSubmit = (data, e) => {
		Axios.post(
			"https://rampurhospital.lumbini.gov.np/api/public/api/appointment/store",
			data
		)
			.then(function (response) {
				setAppointmentResponse(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
		e.target.reset();
	};
	return (
		<React.Fragment>
			<div className="appointment-page">
				<div className="container">
					<div className="appointment">
						<div className="appointment-title">
							<FormattedMessage
								id="make_appointment"
								defaultMessage={defineMessages.pages.make_appointment}
							/>
						</div>
						<div className="appointment-subtitle">
							<FormattedMessage
								id="appointment_subtitle"
								defaultMessage={defineMessages.pages.appointment_subtitle}
							/>
						</div>
						{AppointmentResponse.success && (
							<div className="alert alert-success my-2">
								{AppointmentResponse.success}
							</div>
						)}

						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label htmlFor="">
											<FormattedMessage
												id="name"
												defaultMessage={defineMessages.pages.name}
											/>
										</label>

										<input
											type="text"
											name="name"
											className="form-control"
											ref={register({ required: true })}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">
											<FormattedMessage
												id="mobile_number"
												defaultMessage={defineMessages.pages.mobile_number}
											/>
										</label>
										<input
											type="number"
											name="phone"
											className="form-control"
											ref={register({ required: true })}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label htmlFor="">
											<FormattedMessage
												id="appointment_date"
												defaultMessage={defineMessages.pages.appointment_date}
											/>
										</label>
										<input
											type="date"
											name="desired_date"
											className="form-control"
											ref={register({ required: true })}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">
											<FormattedMessage
												id="appointment_department"
												defaultMessage={
													defineMessages.pages.appointment_department
												}
											/>
										</label>
										<select
											id=""
											name="department"
											className="form-control"
											ref={register({ required: true })}
										>
											{Department &&
												Department.map((category, index) => (
													<option key={index} value={category}>
														{category}
													</option>
												))}
										</select>
									</div>
								</div>
							</div>

							<div className="button-container">
								<button type="submit">
									Submit <i className="fa fa-paper-plane"></i>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
