import React, { useState, useEffect, useContext } from "react";
import { useParams} from 'react-router-dom';
import Axios from 'axios';
import { HeaderContext } from "../Includes/HeaderContext";

export default function DoctorSingle() {
	let { slug } = useParams();
	const [SingleDoctorResponse, setSingleDoctorResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const {LangData} = useContext(HeaderContext);
	

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/doctors/" + slug +"/"+ LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl,
					{
						timeout: 10000
					},
					{
						cancelToken: source.token
					}
				);
				setSingleDoctorResponse(response.data.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
		return () => {
			source.cancel();
		};
	}, [getUrl]);
	console.log(SingleDoctorResponse);
	useEffect(()=>{
        window.scrollTo(0,0);
    },[])
	return (
		<React.Fragment>
		
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<div className="img-container">
							<img
								srcSet={`${SingleDoctorResponse.lg} 250w, ${SingleDoctorResponse.sm} 140w`}
								src={SingleDoctorResponse.lg}
								alt={SingleDoctorResponse.name}
								className="img-fluid"
							/>
						</div>
						<div className="doctor-wrapper">
							<div className="doctor-name">{SingleDoctorResponse.name}</div>
							<div className="doctor-designation">{SingleDoctorResponse.speciality}</div>
						</div>
                        <div className="opd-schedule">
                            <div className="schedule-title">
                                OPD SCHedule
                            </div>
                            <div className="day">
                                {SingleDoctorResponse.arrivalDay} - {SingleDoctorResponse.departureDay}
                            </div>
                            <div className="time">
                                {SingleDoctorResponse.arrivalTime} - {SingleDoctorResponse.departureTime}
                            </div>
                        </div>
					</div>
					<div className="col-md-8">
						<div className="doctor-profile">
							<div className="title">Professional Journey</div>
							<div className="about-long" dangerouslySetInnerHTML={{__html:SingleDoctorResponse.professionalJourney}}>
								
								</div>
							
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
