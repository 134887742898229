import React, { createContext, useState, useEffect, useContext } from "react";
import Axios from "axios";
export const HeaderContext = createContext(null);
export default ({ children }) => {
	const [HeaderResponse, setHeaderResponse] = useState([]);
	const [locale, setLocale] = useState("np");
	const [Notice, setNotice] = useState([]);
	let getUrl = "https://rampurhospital.lumbini.gov.np/api/public/api/newseventnotice/" + locale;
	
	useEffect(() => {
		Axios.get(
			getUrl
		).then((response) => {
			setNotice(response.data.data.notices);
		});
		
	}, [getUrl]);

	// const [sharing, setSharing] = useState([])
	// const [help, setHelp] = useState([])

	const store = {
		//   sharing: [sharing, setSharing],
		LangData: [locale, setLocale],
		Notice: [Notice, setNotice],
		HeaderData: [HeaderResponse, setHeaderResponse],
	};

	return (
		<HeaderContext.Provider value={store}>{children}</HeaderContext.Provider>
	);
};
