import React, { useState, useContext } from "react";
import { IntlProvider } from "react-intl";

import English from "../Lang/en.json";
import Nepali from "../Lang/np.json";
import {HeaderContext} from "../Includes/HeaderContext";
import { useEffect } from "react";

export default function LanguageProvider(props) {
	const { LangData } = useContext(HeaderContext);

	const [lang, setLang] = useState(Nepali);

	useEffect(()=>{
       
		switch (LangData[0]) {
			case "en":
				setLang(English);
				break;
			case "np":
				setLang(Nepali);
				break;
			default:
				setLang(English);
		};
    },[LangData])
		
	

	return (
        <React.Fragment>
        { LangData[0] &&
		<IntlProvider locale="en" messages={lang}>
			<div>{props.children}</div>
        </IntlProvider>
        }
        </React.Fragment>
	);
}
