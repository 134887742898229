import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, Link } from "react-router-dom";
import NoticeSingle from "./NoticeSingle";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";
import Axios from "axios";
import RecentNews from "../Component/RecentNews";
import RecentEvent from "../Component/RecentEvent";
import { HeaderContext } from "../Includes/HeaderContext";

export default function Notice() {
	const [NoticeResponse, setNoticeResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const [pagination, setPagination] = useState([]);
	const { LangData } = useContext(HeaderContext);

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/notice/" + LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl
					
				);
				setNoticeResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
	
	}, [getUrl]);
	console.log(pagination);
	console.log(NoticeResponse);
	const PaginateData = async (PageNumber) => {
		try {
			const response = await Axios.get(
				`https://rampurhospital.lumbini.gov.np/api/public/api/notice/${LangData[0]}?page=${PageNumber}`
			);
			setNoticeResponse(response.data);
			setPagination(response.data.meta);
		} catch (error) {
			if (Axios.isCancel(error)) {
				console.log(error);
			} else {
				setErr(() => {
					throw error;
				});
			}
		}
		window.scrollTo(0, 0);
	};
	const SetPage = async (link) => {
		if (link != null) {
			try {
				const response = await Axios.get(link);
				setNoticeResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
			window.scrollTo(0, 0);
		}
	};

	return (
		<React.Fragment>
			<div className="news-notice-section">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="content-section">
								<div className="notice-section">
									<div className="main-title">
										<FormattedMessage
											id="navbar_notice"
											defaultMessage={defineMessages.navbar.navbar_notice}
										/>
									</div>
									{
										<Switch>
											<Route exact path="/notice">
												{NoticeResponse.data &&
													NoticeResponse.data.map((notice, index) => (
														<div className="notice-wrapper" key={notice.id}>
															<div className="title">{notice.title}</div>
															<div
																className="short-description"
																dangerouslySetInnerHTML={
																	notice && { __html: notice.short }
																}
															></div>
															<div className="button-container">
																<Link to={`/notice/${notice.id}`}>
																	View Detail
																</Link>
															</div>
															<div className="publish-date">
																<small>{notice.publishedDate}</small>
															</div>
														</div>
													))}
												<ul class="pagination justify-content-center">
													<li class="page-item">
														<a
															class="page-link"
															onClick={() => SetPage(NoticeResponse.links.prev)}
														>
															Previous
														</a>
													</li>

													{pagination &&
														Array.apply(0, Array(pagination.last_page)).map(
															(x, i) => (
																<li
																	className={`page-item ${
																		pagination.current_page == i + 1
																			? "active"
																			: ""
																	}`}
																>
																	<a
																		class="page-link"
																		onClick={() => PaginateData(i + 1)}
																	>
																		{i + 1}
																	</a>
																</li>
															)
														)}

													<li class="page-item">
														<a
															class="page-link"
															onClick={() => SetPage(NoticeResponse.links.next)}
														>
															Next
														</a>
													</li>
												</ul>
											</Route>
											<Route path="/notice/:slug">
												<NoticeSingle />
											</Route>
										</Switch>
									}
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="sidebar-section">
								<RecentNews />
								<RecentEvent />
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
