import React ,{Suspense} from "react";
import Slider from "../Component/Slider";
const Welcome = React.lazy(() => import("../Component/Welcome"));
const Testimonial = React.lazy(() => import("../Component/Testimonial"));


export default function Main() {
	return (
		<div className="content">
			<Slider />
			<Suspense fallback={<h1 className="text-center">Slider is Loading</h1>}>
				<Welcome />
				<Testimonial />
			</Suspense>
		</div>
	);
}
