import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { HeaderContext } from "./HeaderContext";
import LanguageProvider from "../Lang/LanguageProvider";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";
import { Link } from "react-router-dom";
import { useLocalStorage } from "../LocalStorageHook";


export default function Navbar() {
	const { HeaderData, LangData, Notice } = useContext(HeaderContext);
	const [Lang, setlang]=useState('np');	
	const [navResponse, setNavResponse] = useLocalStorage('navData'+Lang);
	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/header/" + LangData[0];

		useEffect(() => {
			Axios.get(getUrl).then((res) => {
				setNavResponse(res.data.data);
				HeaderData[1](res.data.data);
			});
			if(LangData[0]=="" && LangData[0]=="en"){
				setlang('en');
			}
		}, [getUrl]);
		useEffect(() => {
			window.onscroll = function () {
				myFunction();
			};
			var secondNavbar = document.getElementById("secondNavbar");
			var sticky = secondNavbar.offsetTop + 120;
			function myFunction() {
				if (window.pageYOffset >= sticky ) {
					secondNavbar.classList.add("sticky");
				} else {
					secondNavbar.classList.remove("sticky");
				}
			}
		}, []);
	function openNav() {
		document.getElementById("myNav").style.height = "100%";
		document.getElementById("myNav").style.width = "100%";
	}
	function exitNav(e) {
		e.preventDefault();
		document.getElementById("myNav").style.height = "0%";
	}
	function closeNav() {
		document.getElementById("myNav").style.height = "0%";
	}

	return (
    <React.Fragment>
      {navResponse && (
        <div className="slim-navbar container-fluid">
          <div className="company_province">{navResponse.province}</div>
          <div className="company_ministry d-none d-md-block">
            {navResponse.ministry}
          </div>
          <div className="phone">
            <i className="fa fa-phone"></i> {navResponse.phone}
          </div>
        </div>
      )}

      <div id="navbar">
        <div className="container-fluid d-flex justify-content-between">
          <Link to="/" className="logo logo-company">
            <img
              src={require("../images/logo.png")}
              alt=""
              className="img-fluid"
            />
          </Link>
          {navResponse && (
            <div className="right-container">
              <div className="company_ministry d-block d-md-none">
                {navResponse.ministry}
              </div>
              <div className="company_name ">{navResponse.company}</div>
              <div className="company_address">{navResponse.address}</div>
            </div>
          )}
          <div id="secondNavbar" className="d-none d-xl-block">
            <div className="container ">
              <LanguageProvider>
                <div className="nav  d-flex justify-content-around">
                  <NavLink to="/">
                    <FormattedMessage
                      id="home"
                      defaultMessage={defineMessages.navbar.home}
                    />
                  </NavLink>

                  <div className="dropdown">
                    <button className="dropbtn">
                      <FormattedMessage
                        id="overview"
                        defaultMessage={defineMessages.navbar.overview}
                      />{" "}
                      <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="dropdown-content">
                      <NavLink to="/about">
                        <FormattedMessage
                          id="navbar_about"
                          defaultMessage={defineMessages.navbar.navbar_about}
                        />
                      </NavLink>
                      <NavLink to="/mission-vision">
                        <FormattedMessage
                          id="navbar_mission"
                          defaultMessage={defineMessages.navbar.navbar_mission}
                        />
                      </NavLink>
                      <NavLink to="chairman-note">
                        <FormattedMessage
                          id="navbar_chairman"
                          defaultMessage={defineMessages.navbar.navbar_chairman}
                        />
                      </NavLink>
                      <NavLink to="/services">
                        <FormattedMessage
                          id="navbar_service"
                          defaultMessage={defineMessages.navbar.navbar_service}
                        />
                      </NavLink>
                      <NavLink to="/gallery">
                        <FormattedMessage
                          id="navbar_gallery"
                          defaultMessage={defineMessages.navbar.navbar_gallery}
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="dropdown">
                    <button className="dropbtn">
                      <FormattedMessage
                        id="staff_administration"
                        defaultMessage={
                          defineMessages.navbar.staff_administration
                        }
                      />
                      <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="dropdown-content">
                      <NavLink to="/administrative-staff">
                        <FormattedMessage
                          id="administration"
                          defaultMessage={defineMessages.navbar.administration}
                        />
                      </NavLink>
                      <NavLink to="/doctor">
                        <FormattedMessage
                          id="doctor"
                          defaultMessage={defineMessages.navbar.doctor}
                        />
                      </NavLink>
                    </div>
                  </div>
                  <NavLink to="/appointment">
                    <FormattedMessage
                      id="appointment"
                      defaultMessage={defineMessages.navbar.appointment}
                    />
                  </NavLink>

                  <div className="dropdown">
                    <button className="dropbtn">
                      <FormattedMessage
                        id="news_notice"
                        defaultMessage={defineMessages.navbar.news_notice}
                      />{" "}
                      <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="dropdown-content">
                      <div className="dropdown-content">
                        <NavLink to="/notice">
                          <FormattedMessage
                            id="navbar_notice"
                            defaultMessage={defineMessages.navbar.navbar_notice}
                          />
                        </NavLink>
                        <NavLink to="/news">
                          <FormattedMessage
                            id="navbar_news"
                            defaultMessage={defineMessages.navbar.navbar_news}
                          />
                        </NavLink>
                        <NavLink to="/event">
                          <FormattedMessage
                            id="navbar_event"
                            defaultMessage={defineMessages.navbar.navbar_event}
                          />
                        </NavLink>

                        <NavLink to="/download">
                          <FormattedMessage
                            id="navbar_download"
                            defaultMessage={
                              defineMessages.navbar.navbar_download
                            }
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <NavLink to="/contact">
                    <FormattedMessage
                      id="contact"
                      defaultMessage={defineMessages.navbar.contact}
                    />
                  </NavLink>
                </div>
              </LanguageProvider>
              <span onClick={openNav} className="d-block d-sm-none">
                <i className="fa fa-bars"></i>
              </span>
            </div>
          </div>

          <div className="logo logo-flag">
            <img
              src={require("../images/flagNepal.gif")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div id="myNav" className="overlay">
        <a href="" className="closebtn" onClick={exitNav}>
          &times;
        </a>
        <LanguageProvider>
          <div className="overlay-content">
            <NavLink to="/" onClick={closeNav}>
              <FormattedMessage
                id="home"
                defaultMessage={defineMessages.navbar.home}
              />
            </NavLink>

            <div className="dropdown">
              <button className="dropbtn">
                <FormattedMessage
                  id="overview"
                  defaultMessage={defineMessages.navbar.overview}
                />{" "}
                <i className="fa fa-caret-down"></i>
              </button>
              <div className="dropdown-content">
                <NavLink to="/about" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_about"
                    defaultMessage={defineMessages.navbar.navbar_about}
                  />
                </NavLink>
                <NavLink to="/mission-vision" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_mission"
                    defaultMessage={defineMessages.navbar.navbar_mission}
                  />
                </NavLink>
                <NavLink to="chairman-note" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_chairman"
                    defaultMessage={defineMessages.navbar.navbar_chairman}
                  />
                </NavLink>
                <NavLink to="/services" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_service"
                    defaultMessage={defineMessages.navbar.navbar_service}
                  />
                </NavLink>
                <NavLink to="/gallery" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_gallery"
                    defaultMessage={defineMessages.navbar.navbar_gallery}
                  />
                </NavLink>
              </div>
            </div>

            <div className="dropdown">
              <button className="dropbtn">
                <FormattedMessage
                  id="staff_administration"
                  defaultMessage={defineMessages.navbar.staff_administration}
                />
                <i className="fa fa-caret-down"></i>
              </button>
              <div className="dropdown-content">
                <NavLink to="/administrative-staff" onClick={closeNav}>
                  <FormattedMessage
                    id="administration"
                    defaultMessage={defineMessages.navbar.administration}
                  />
                </NavLink>
                <NavLink to="/doctor" onClick={closeNav}>
                  <FormattedMessage
                    id="doctor"
                    defaultMessage={defineMessages.navbar.doctor}
                  />
                </NavLink>
              </div>
            </div>
            <NavLink to="/appointment" onClick={closeNav}>
              <FormattedMessage
                id="appointment"
                defaultMessage={defineMessages.navbar.appointment}
              />
            </NavLink>

            <div className="dropdown">
              <button className="dropbtn">
                <FormattedMessage
                  id="news_notice"
                  defaultMessage={defineMessages.navbar.news_notice}
                />{" "}
                <i className="fa fa-caret-down"></i>
              </button>
              <div className="dropdown-content">
                <NavLink to="/notice" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_notice"
                    defaultMessage={defineMessages.navbar.navbar_notice}
                  />
                </NavLink>
                <NavLink to="/news" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_news"
                    defaultMessage={defineMessages.navbar.navbar_news}
                  />
                </NavLink>
                <NavLink to="/event" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_event"
                    defaultMessage={defineMessages.navbar.navbar_event}
                  />
                </NavLink>

                <NavLink to="/download" onClick={closeNav}>
                  <FormattedMessage
                    id="navbar_download"
                    defaultMessage={defineMessages.navbar.navbar_download}
                  />
                </NavLink>
              </div>
            </div>

            <NavLink to="/contact" onClick={closeNav}>
              <FormattedMessage
                id="contact"
                defaultMessage={defineMessages.navbar.contact}
              />
            </NavLink>
          </div>
        </LanguageProvider>
      </div>

      <div className="top-navbar">
        <div className="container d-flex justify-content-between">
          <span onClick={openNav} className="d-block">
            <i className="fa fa-bars"></i>
          </span>

          {LangData[0] && LangData[0] == "np" ? (
            <span className="language-btn" onClick={() => LangData[1]("en")}>
              {" "}
              Eng
            </span>
          ) : (
            <span className="language-btn" onClick={() => LangData[1]("np")}>
              {" "}
              नेप{" "}
            </span>
          )}
        </div>
      </div>
      <div className="marquee-notice">
        <LanguageProvider>
          <div className="title">
            <FormattedMessage
              id="navbar_notice"
              defaultMessage={defineMessages.navbar.navbar_notice}
            />
          </div>
        </LanguageProvider>

        <div className="content">
          <div
            id="notice-carousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              {Notice[0] &&
                Notice[0].map((noticeItem, index) => (
                  <div
                    className={`carousel-item  ${index == 0 ? "active" : ""}`}
                    key={index}
                  >
                    <Link to={`/notice/${noticeItem.id}`}>
                      {noticeItem.title}
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="lang-btn d-none d-md-flex align-items-center">
          {LangData[0] && LangData[0] == "np" ? (
            <span className="language-btn" onClick={() => LangData[1]("en")}>
              {" "}
              Eng
            </span>
          ) : (
            <span className="language-btn" onClick={() => LangData[1]("np")}>
              {" "}
              नेप{" "}
            </span>
          )}
          <a
            className="language-btn"
            style={{ marginLeft: "15px" }}
            href="https://rampurhospital.lumbini.gov.np/api/public/login"
            target="_blank"
          >
            Login
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
