import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

export default function SingleGallery() {
	const [AlbumRes, setAlbumRes] = useState();
	const [err, setErr] = useState();
    const { slug, lang } = useParams();
    const [modalImage, setModalImage] = useState();

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/album/" + slug + "/" + lang;

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await Axios.get(getUrl);
				setAlbumRes(response.data.data);
				console.log(response.data.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
		
    }, []);
    function handleImage(image){
        console.log(image)
        setModalImage(image)
    }
   

	return (
		<div className="gallery-container">
			<div className="container">
				<h2>Gallery</h2>
				<div className="row">
					{AlbumRes &&
						AlbumRes.map((album, index) => (
							<div className="col-md-3" key={index}>
								<a className="image-item" data-toggle="modal" data-target="#gallery_modal"  onClick={()=>handleImage(album.image)}>
									<div className="img-container">
										<img
											src={album.image}
											alt="gulmi Hospital"
											className="img-fluid"
										/>
									</div>
								</a>
							</div>
						))}
				</div>
			</div>
            <div className="modal" id="gallery_modal">
  <div className="modal-dialog modal-lg">
    <div className="modal-content">

     
      <div className="modal-body">
      <button type="button" className="close" data-dismiss="modal" onClick={()=>setModalImage("")}>&times;</button>
       <div className="img-container">
         {
             modalImage &&
             <img src={modalImage} alt="gulmi hospital" className="img-fluid"/>
         }
       </div>
      </div>

     

    </div>
  </div>
</div>
		</div>
	);
}
