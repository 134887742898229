import React, { useEffect, useState, useContext } from "react";

import { Route, Switch, Link } from "react-router-dom";
import NewsSingle from "./NewsSingle";
import Axios from "axios";
import RecentNotice from "../Component/RecentNotice";
import RecentEvent from "../Component/RecentEvent";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";
import { HeaderContext } from "../Includes/HeaderContext";

export default function News() {
	const [NewsResponse, setNewsResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const [pagination, setPagination] = useState([]);
	const { LangData } = useContext(HeaderContext);

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/news/" + LangData[0];

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl
				);
				setNewsResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
	
	}, [getUrl]);
	console.log(NewsResponse);
	const PaginateData = async (PageNumber) => {
		try {
			const response = await Axios.get(
				`https://rampurhospital.lumbini.gov.np/api/public/api/news/${LangData[0]}?page=${PageNumber}`
				
			);
			setNewsResponse(response.data);
			setPagination(response.data.meta);
		} catch (error) {
			if (Axios.isCancel(error)) {
				console.log(error);
			} else {
				setErr(() => {
					throw error;
				});
			}
		}
		window.scrollTo(0, 0);
	};
	const SetPage = async (link) => {
		if (link != null) {
			try {
				const response = await Axios.get(link);
				setNewsResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
			window.scrollTo(0, 0);
		}
	};

	return (
		<React.Fragment>
			<div className="news-notice-section">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="content-section">
								<div className="news-section">
									<div className="main-title">
										<FormattedMessage
											id="navbar_news"
											defaultMessage={defineMessages.navbar.navbar_news}
										/>
									</div>
									{
										<Switch>
											<Route exact path="/news">
												<div className="row">
													{NewsResponse.data &&
														NewsResponse.data.map((news, index) => (
															<div className="col-md-6">
																<Link to={`/news/${news.id}`}>
																	<div className="img-container">
																		<img
																			src={news.image}
																			alt={news.title}
																			className="img-fluid"
																		/>
																		<div className="overlay">
																			<div className="title">{news.title}</div>
																			<div className="date">March 03, 2020</div>
																		</div>
																	</div>
																</Link>
															</div>
														))}
												</div>
												<ul class="pagination justify-content-center">
													<li class="page-item">
														<a
															class="page-link"
															onClick={() => SetPage(NewsResponse.links.prev)}
														>
															Previous
														</a>
													</li>

													{pagination &&
														Array.apply(0, Array(pagination.last_page)).map(
															(x, i) => (
																<li
																	className={`page-item ${
																		pagination.current_page == i + 1
																			? "active"
																			: ""
																	}`}
																	key={i}
																>
																	<a
																		class="page-link"
																		onClick={() => PaginateData(i + 1)}
																	>
																		{i + 1}
																	</a>
																</li>
															)
														)}

													<li class="page-item">
														<a
															class="page-link"
															onClick={() => SetPage(NewsResponse.links.next)}
														>
															Next
														</a>
													</li>
												</ul>
											</Route>
											<Route path={`/news/:slug`}>
												<NewsSingle />
											</Route>
										</Switch>
									}
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="sidebar-section">
								<RecentNotice />
								<RecentEvent />
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
