import React,{useState, useEffect, useContext} from 'react';
import Axios from 'axios';
import { HeaderContext } from '../Includes/HeaderContext';

export default function Privacy() {
    const [PrivacyResponse, setPrivacyResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const {LangData} = useContext(HeaderContext);

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/pages/" + LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl
				);
				setPrivacyResponse(response.data.data[0]);
				
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
        loadData();
        window.scrollTo(0, 0);
		
       
	}, [getUrl]);
	console.log(PrivacyResponse);


    return(

        <div className="privacy container text-justify my-5">
            <h3>{PrivacyResponse.title}</h3>
            <p className="mt-4">{PrivacyResponse.content}</p>
        </div>
    )
}