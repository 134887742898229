import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useLocalStorage } from "../LocalStorageHook";

export default function Slider() {
	const [SliderResponse, setSliderResponse] = useLocalStorage("SliderResponse");
	const [err, setErr] = useState();

	let getUrl = "https://rampurhospital.lumbini.gov.np/api/public/api/slider";

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await Axios.get(getUrl);
				setSliderResponse(response.data.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
	}, []);

	return (
		<div style={{ backgroundColor: "#fff" }}>
			<div
				id="hospitalCarousel"
				className="carousel slide"
				data-ride="carousel"
			>
				<ul className="carousel-indicators">
					<li
						data-target="#hospitalCarousel"
						data-slide-to="0"
						className="active"
					></li>
					<li data-target="#hospitalCarousel" data-slide-to="1"></li>
					<li data-target="#hospitalCarousel" data-slide-to="2"></li>
				</ul>

				<div className="carousel-inner">
					{SliderResponse &&
						SliderResponse.map((slider, index) => (
							<div
								className={`carousel-item ${index == 1 ? "active" : ""}`}
								key={slider.id}
							>
								<img
									srcSet={`${slider.lg} 1920w, ${slider.md} 1400w, ${slider.sm} 400w`}
									// src={slider.md}
									alt="Kapilvastu Hospital"
									className="img-fluid"
								/>
							</div>
						))}
				</div>

				<a
					className="carousel-control-prev"
					href="#hospitalCarousel"
					data-slide="prev"
				>
					<span className="carousel-control-prev-icon"></span>
				</a>
				<a
					className="carousel-control-next"
					href="#hospitalCarousel"
					data-slide="next"
				>
					<span className="carousel-control-next-icon"></span>
				</a>
			</div>
		</div>
	);
}
