import { defineMessages } from "react-intl";

export default defineMessages({
	lang: {
		welcome: "Welcome to Rampur Hospital",
		home_title1: "Services We Offer",
		officer: "Officerr",
		what_people_are_sayin: "what people are saying",
		notice: "Notice",
		news: "News",
		event: "Event",
		testimonial: "What people are Saying",
	},
	navbar: {
		home: "Home",
		appointment: "Appointment",
		doctor: "Staff",
		administration: "HDC",
		contact: "Contact",
		overview: "Office Overview",
		staff_administration: "Our Members",
		news_notice: "News & Notice",
		navbar_notice: "Notice",
		navbar_news: "News",
		navbar_event: "Event",
		navbar_about: "About",
		navbar_mission: "Mission & Vision",
		navbar_service: "Services",
		navbar_chairman: "Chairman Note",
		navbar_gallery: "Gallery",
		navbar_case: "Case of the Month",
		navbar_download: "Downloads"
	},
	pages: {
		recent_notice: "Recent Notice",
		recent_news: "Recent News",
		recent_event: "Recent Event",
		service_offered: "Services Offered By Rampur Hospital",
		make_appointment: "Make an Appointment",
		appointment_subtitle:
			"The appointment facility on our website allows the patients to connect to their doctors without any hassle of lines and form fill up. Through the online procedure, the service receiver is able to directly book a specific time with the doctor in order to consult their specific problem and receive treatment",
		name: "Name",
		mobile_number: "Mobile Number",
		appointment_date: "Appointmeent Date",
		appointment_department: "Appointment Department",
		doctors_hospital: "Doctors in Rampur Hospital",
		hospital_services: "Services Offered By Rampur Hospital",
		administrative_staff: "Administravtive Staff",
	},
});
