import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import { HeaderContext } from "../Includes/HeaderContext";
import { Link } from "react-router-dom";
export default function NoticeSingle() {
	const [SingleNoticeResponse, setSingleNoticeResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	let { slug } = useParams();
	const { LangData } = useContext(HeaderContext);

	const history = useHistory();

	const handleBack = () => {
		history.goBack();
	};
	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/notice/" +
		slug +
		"/" +
		LangData[0];

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl
				);
				setSingleNoticeResponse(response.data.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
	
	}, [getUrl]);

	console.log(SingleNoticeResponse);
	return (
		<React.Fragment>
			<div className="back-button">
				<a href={(e) => e.preventDefault()} onClick={handleBack}>
					<i className="fa fa-arrow-left"></i>
				</a>
			</div>
			<div className="notice-wrapper">
				<div className="title">{SingleNoticeResponse.title}</div>
				<div
					className="long-description"
					dangerouslySetInnerHTML={
						SingleNoticeResponse && { __html: SingleNoticeResponse.long }
					}
				></div>
				{SingleNoticeResponse.image != null && (
					<div className="img-container">
						<img
							src={SingleNoticeResponse.image}
							className="img-fluid"
							alt=""
						/>
					</div>
				)}
				{SingleNoticeResponse.file == true && (
					<div className="additional-container">
						<div className="file-title">Aditional document Download</div>
						<div className="download-btn">
							<a
								target="_blank"
								href={`https://kapilvastuapi.shoonyatechnology.com/api/notice/download/${SingleNoticeResponse.id}`}
							>
								<i className="fa fa-download"></i>
							</a>
						</div>
					</div>
				)}
				<div className="date">{SingleNoticeResponse.publishedDate}</div>
			</div>
		</React.Fragment>
	);
}
