import React from 'react';


export default function PageNotFound() {

    return(
        <React.Fragment>
        <h1 className="text-center mt-5"> Page Not Found</h1>
        <h5 className="text-center mb-5">Please Refresh your Page</h5>
        </React.Fragment>
    )
}