import React, { useEffect, useState, useContext } from "react";

import {
	Link,
	Switch,
	Route,
	useRouteMatch,
	useParams,
} from "react-router-dom";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";
import Axios from "axios";
import DoctorSingle from "./DoctorSingle";
import { HeaderContext } from "../Includes/HeaderContext";
import { useLocalStorage } from "../LocalStorageHook";

export default function Doctor() {
	let { url, path } = useRouteMatch();
	let { slug } = useParams();
	const [DoctorResponse, setDoctorResponse] = useLocalStorage("DoctorResponse");
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const [pagination, setPagination] = useState([]);
	const { LangData } = useContext(HeaderContext);

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/doctors/" + LangData[0];

	useEffect(() => {
		let source = Axios.CancelToken.source();
		const loadData = async () => {
			try {
				const response = await Axios.get(
					getUrl,
					{
						timeout: 10000,
					},
					{
						cancelToken: source.token,
					}
				);
				setDoctorResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
		return () => {
			source.cancel();
		};
	}, [getUrl]);
	console.log(DoctorResponse);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const PaginateData = async (PageNumber) => {
		try {
			const response = await Axios.get(
				`https://rampurhospital.lumbini.gov.np/api/public/api/doctors/${LangData[0]}?page=${PageNumber}`,
				{
					timeout: 10000,
				}
			);
			setDoctorResponse(response.data);
			setPagination(response.data.meta);
		} catch (error) {
			if (Axios.isCancel(error)) {
				console.log(error);
			} else {
				setErr(() => {
					throw error;
				});
			}
		}
		window.scrollTo(0, 0);
	};
	const SetPage = async (link) => {
		if (link != null) {
			try {
				const response = await Axios.get(link, {
					timeout: 10000,
				});
				setDoctorResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
			window.scrollTo(0, 0);
		}
	};

	return (
		<React.Fragment>
			<div className="doctor-container">
				{
					<Switch>
						<Route exact path={path}>
							<div className="main-title">
								<FormattedMessage
									id="doctor"
									defaultMessage={defineMessages.navbar.doctor}
								/>
							</div>
							<div className="container">
								<div className="row">
									{DoctorResponse &&
										DoctorResponse.data &&
										DoctorResponse.data.map((doctor, index) => (
											<div className="col-md-3 col-6" key={index}>
												<div className="doctor-wrapper">
													<div className="staff-item">
														<div className="img-container">
															<img
																srcSet={`${doctor.lg} 250w, ${doctor.sm} 140w`}
																src={doctor.lg}
																alt={doctor.name}
																className="img-fluid"
															/>
														</div>
														<div className="category">
															<Link to={`${url}/${doctor.slug}`}>
																View Profile
															</Link>
														</div>
													</div>

													<div className="doctor-name">{doctor.name}</div>
													<div className="doctor-designation">
														{doctor.department}
													</div>
												</div>
											</div>
										))}
								</div>
							</div>
							<ul className="pagination justify-content-center">
								<li className="page-item">
									<a
										className="page-link"
										onClick={() => SetPage(DoctorResponse.links.prev)}
									>
										Previous
									</a>
								</li>

								{pagination &&
									Array.apply(0, Array(pagination.last_page)).map((x, i) => (
										<li
											className={`page-item ${
												pagination.current_page == i + 1 ? "active" : ""
											}`}
											key={i}
										>
											<a
												className="page-link"
												onClick={() => PaginateData(i + 1)}
											>
												{i + 1}
											</a>
										</li>
									))}

								<li className="page-item">
									<a
										className="page-link"
										onClick={() => SetPage(DoctorResponse.links.next)}
									>
										Next
									</a>
								</li>
							</ul>
						</Route>
						<Route path={`${path}/:slug`}>
							<DoctorSingle />
						</Route>
					</Switch>
				}
			</div>
		</React.Fragment>
	);
}
