import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, Link } from "react-router-dom";
import NoticeSingle from "./NoticeSingle";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";
import Axios from "axios";
import RecentNews from "../Component/RecentNews";
import RecentEvent from "../Component/RecentEvent";
import { HeaderContext } from "../Includes/HeaderContext";

export default function Download() {
	const [DownloadResponse, setDownloadResponse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState();
	const [pagination, setPagination] = useState([]);
	const { LangData } = useContext(HeaderContext);

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/downloads/" + LangData[0];

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await Axios.get(getUrl);
				setDownloadResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
	}, [getUrl]);
	console.log(pagination);
	console.log(DownloadResponse);
	const PaginateData = async (PageNumber) => {
		try {
			const response = await Axios.get(
				`https://rampurhospital.lumbini.gov.np/api/public/api/download/${LangData[0]}?page=${PageNumber}`
			);
			setDownloadResponse(response.data);
			setPagination(response.data.meta);
		} catch (error) {
			if (Axios.isCancel(error)) {
				console.log(error);
			} else {
				setErr(() => {
					throw error;
				});
			}
		}
		window.scrollTo(0, 0);
	};
	const SetPage = async (link) => {
		if (link != null) {
			try {
				const response = await Axios.get(link);
				setDownloadResponse(response.data);
				setPagination(response.data.meta);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
			window.scrollTo(0, 0);
		}
	};

	return (
		<React.Fragment>
			<div className="news-notice-section">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="content-section">
								<div className="download-section">
									<div className="main-title">
										<FormattedMessage
											id="navbar_download"
											defaultMessage={defineMessages.navbar.navbar_download}
										/>
									</div>
									{
										<Switch>
											<Route exact path="/download">
												{DownloadResponse.data &&
													DownloadResponse.data.map((download, index) => (
														<div className="download-wrapper" key={download.id}>
															<div className="title">{download.title}</div>
															
															<div className="button-container">
																<a href={download.downloadlink}>
																	<i className="fa fa-download"></i>
																</a>
															</div>
														
														</div>
													))}
											
											</Route>
										
										</Switch>
									}
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="sidebar-section">
								<RecentNews />
								<RecentEvent />
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
