import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { HeaderContext } from "../Includes/HeaderContext";
import { useLocalStorage } from "../LocalStorageHook";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/Defaultlang";

export default function Staff() {
	const [AdministrativeResponse, setAdministrativeResponse] = useLocalStorage(
		"AdministrativeResponse"
	);

	const [err, setErr] = useState();
	const { LangData } = useContext(HeaderContext);

	let getUrl =
		"https://rampurhospital.lumbini.gov.np/api/public/api/administrative/" +
		LangData[0];

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await Axios.get(getUrl);
				setAdministrativeResponse(response.data.data);
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log(error);
				} else {
					setErr(() => {
						throw error;
					});
				}
			}
		};
		loadData();
		window.scrollTo(0, 0);
	}, [getUrl]);
	console.log(AdministrativeResponse);

	return (
		<React.Fragment>
			<div className="staff-container">
				<div className="main-title">
					<FormattedMessage
						id="administration"
						defaultMessage={defineMessages.navbar.administration}
					/>
				</div>
				<div className="container">
					<table className="table table-bordered table-striped">
						<thead>
							<tr>
								<td>S.N.</td>
								<td>Name</td>
								<td>Designation</td>
							</tr>
						</thead>
						<tbody>
							{AdministrativeResponse &&
								AdministrativeResponse.map((administrative, index) => (
									<tr key={index}>
										<td>{index+1}</td>
										<td>{administrative.name}</td>
										<td>{administrative.department}</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</React.Fragment>
	);
}
